import $ from "jquery";

const dmmGamesModal = $("#w");
const close = $("#dmm-pop-close");

const open = $(".dmm-games-pop");

//モーダルを開く時
open.on("click", (e) => {
  if ($(e.currentTarget).data("dmm") !== "dmmGames") return;
  dmmGamesModal.removeClass("disp-none");
  dmmGamesModal.addClass("disp-block");
  dmmGamesModal.addClass("fadeIn");
  openModal();
});

// モーダルを閉じる時
close.on("click", () => {
  dmmGamesModal.removeClass("disp-block");
  dmmGamesModal.addClass("disp-none");
  dmmGamesModal.removeClass("fadeIn");
  closeModal();
});

const openModal = () => {
  document.body.style.overflow = "hidden";
};

const closeModal = () => {
  document.body.style.overflow = "";
};
